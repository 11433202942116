<script lang="ts" setup>
const emit = defineEmits<{ stepComplete: [] }>();

const getStarted = () => {
  emit('stepComplete');
};
</script>

<template>
  <div class="mt-5 flex justify-center" data-testid="auth-register-complete-step">
    <UIButton :text="$t('general.auth.registrationComplete.actionButton')" @click="getStarted" />
  </div>
</template>
