<script lang="ts" setup>
import {
  type LoginState,
  type Step,
  STEP_ENTER_EMAIL,
  STEP_ENTER_OTP,
  STEP_STUDENT_REGISTRATION,
  STEP_TEACHER_REGISTRATION,
  STEP_TEACHER_REGISTRATION_COMPLETE,
  STEP_INTEREST_ADDED,
  STEP_TEACHER_REGISTRATION_DISABLED,
} from '~/types/login';
import { SiteRoleType, LoginFlowType } from '~/graphql/generated/graphql';
import { computed, resolveComponent } from 'vue';

interface Props {
  roleType: SiteRoleType;
  fullHeight?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  fullHeight: true,
});

const { t } = useI18n();

const route = useRoute();
const router = useRouter();
const { completeLoginProcess, triggerPostLoginRedirect, user } = useAuth();
const { clear } = useCrossSitesConfig();
const { canAuthedTeacherRegister } = useGhostStudent();
const { cluster } = useConfigStore();

// this is exclusive to student login form with authed teacher and has done ghost preplan
const allowTeacherRoleAutoPrefill = computed(() => props.roleType === SiteRoleType.Student && canAuthedTeacherRegister.value);

const getInitialState = () => {
  const { email, code } = route.query;

  if (typeof email !== 'string' || typeof code !== 'string') {
    return { hasPrefilled: false, email: allowTeacherRoleAutoPrefill.value ? user.value?.primaryEmail || '' : '', otpCode: '' };
  }

  return { hasPrefilled: true, email: email.trim(), otpCode: code.trim() };
};

const analytics = useAnalyticsService();
onMounted(async () => {
  await router.replace({ query: {} });
  analytics.track(EVENTS.LOGIN_PAGE_VIEWED);
});

const { hasPrefilled: initialHasPrefilled, email: initialEmail, otpCode: initialOtpCode } = getInitialState();

const getInitialStep = () => {
  if (allowTeacherRoleAutoPrefill.value) {
    return STEP_STUDENT_REGISTRATION;
  }

  return initialHasPrefilled ? STEP_ENTER_OTP : STEP_ENTER_EMAIL;
};

const currentStep = ref<Step>(getInitialStep());

const loginState = ref<LoginState>({
  email: initialEmail,
  otpCode: initialOtpCode,
  flow: null,
  hasPrefilled: initialHasPrefilled,
});

const reset = () => {
  loginState.value = {
    email: '',
    otpCode: '',
    flow: null,
    hasPrefilled: false,
  };
  currentStep.value = STEP_ENTER_EMAIL;
  clear();
};

const steps = computed(() => {
  return {
    [STEP_ENTER_EMAIL]: {
      component: resolveComponent('CoreAuthLoginStepEnterEmail'),
      header: t('general.auth.enterEmailHeader'), // change this into header i18key
      helpText: null,
      onComplete: () => {
        currentStep.value = STEP_ENTER_OTP;
        analytics.track(EVENTS.LOGIN_EMAIL_SUBMITTED);
      },
    },
    [STEP_ENTER_OTP]: {
      component: resolveComponent('CoreAuthLoginStepEnterOtp'),
      header: null,
      helpText: null,
      onComplete: async () => {
        if (!loginState.value.flow) {
          return;
        }

        const handlers = {
          [LoginFlowType.ExistingRole]: async () => {
            clear();
            await completeLoginProcess();
          },
          [LoginFlowType.NewRole]: () => {
            if (props.roleType === SiteRoleType.Student) {
              currentStep.value = STEP_STUDENT_REGISTRATION;
            } else {
              currentStep.value = cluster?.manageRegisterEnabled ? STEP_TEACHER_REGISTRATION : STEP_TEACHER_REGISTRATION_DISABLED;
            }
          },
        };

        await handlers[loginState.value.flow]();
      },
    },
    [STEP_STUDENT_REGISTRATION]: {
      component: resolveComponent('CoreAuthLoginStepStudentRegister'),
      header: t('general.auth.studentRegisterHeader'),
      helpText: null,
      onComplete: async () => {
        await completeLoginProcess(false);
        analytics.track(EVENTS.ACCOUNT_REGISTER_COMPLETED);
        clear();
        await triggerPostLoginRedirect();
      },
    },
    [STEP_TEACHER_REGISTRATION]: {
      component: resolveComponent('CoreAuthLoginStepTeacherRegister'),
      header: t('general.auth.teacherRegisterHeader'),
      helpText: null,
      onComplete: async () => {
        await completeLoginProcess(false);
        currentStep.value = STEP_TEACHER_REGISTRATION_COMPLETE;
        analytics.track(EVENTS.ACCOUNT_REGISTER_COMPLETED);
        clear();
      },
    },
    [STEP_TEACHER_REGISTRATION_DISABLED]: {
      component: resolveComponent('CoreAuthLoginStepTeacherRegisterDisabled'),
      header: t('general.auth.teacherRegisterDisabledHeader'),
      helpText: t('general.auth.teacherRegisterDisabledHelp', { email: loginState.value.email }),
      // Not implemented yet, just reset
      onComplete: reset,
    },
    [STEP_TEACHER_REGISTRATION_COMPLETE]: {
      component: resolveComponent('CoreAuthLoginStepRegistrationComplete'),
      header: t('general.auth.registrationComplete.teacher.header'),
      helpText: t('general.auth.registrationComplete.teacher.helpText'),
      onComplete: async () => {
        await triggerPostLoginRedirect();
      },
    },
    [STEP_INTEREST_ADDED]: {
      component: resolveComponent('CoreAuthLoginStepInterestAdded'),
      header: t('general.auth.interestAdded.header'),
      helpText: t('general.auth.interestAdded.subtitle'),
      onComplete: reset,
    },
  };
});

const currentStepComponent = computed(() => steps.value[currentStep.value].component);
const currentStepHeader = computed(() => steps.value[currentStep.value].header);
const currentStepHelpText = computed(() => steps.value[currentStep.value].helpText);

const handleCompleteStep = async () => {
  await steps.value[currentStep.value].onComplete();
};

const variantContainerClasses = computed(() => {
  return twMerge(
    cva('flex items-center justify-center px-4', {
      variants: {
        isFullHeight: {
          true: 'min-h-dvh',
          false: '',
        },
      },
    })({
      isFullHeight: props.fullHeight,
    }),
  );
});
</script>

<template>
  <div :class="variantContainerClasses">
    <div class="my-8 w-full max-w-md md:my-12">
      <div class="flex w-full items-center justify-center">
        <CoreClusterLogo class="select-none" customClasses="w-60" variant="lockupPrimaryLight" />
      </div>
      <div v-if="currentStepHeader || currentStepHelpText" class="my-10 flex flex-col items-center justify-center text-center">
        <UIHeading v-if="currentStepHeader" customClasses="font-semibold" size="h1" styleSize="h4">
          {{ currentStepHeader }}
        </UIHeading>
        <UIHeading v-if="currentStepHelpText" class="mt-3" customClasses="font-normal" size="h4" styleSize="h6">
          <UIHtml :html="currentStepHelpText" />
        </UIHeading>
      </div>
      <component :is="currentStepComponent" v-model="loginState" :roleType="roleType" @reset="reset" @step-complete="handleCompleteStep" />
    </div>
  </div>
</template>
