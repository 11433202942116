<script lang="ts" setup>
import { required, email as emailRule } from '@vuelidate/validators';
import type { LoginState, LoginStepProps } from '~/types/login';
import type { FormResult } from '@year13/ui';
import { ref } from 'vue';

const props = defineProps<LoginStepProps>();
const loginState = defineModel<LoginState>({ required: true });
const emit = defineEmits<{ stepComplete: [] }>();
const result = ref<FormResult | null>(null);
const { t } = useI18n();

const { requestLoginCode } = useAuth();

const requestCode = async (doneLoading: () => void) => {
  result.value = null;
  const { error } = await requestLoginCode({ email: loginState.value.email, roleType: props.roleType });

  doneLoading();

  if (error) {
    result.value = { variant: 'error', message: getFirstErrorMessageFromCombinedErrors(t, error) };
    return;
  }

  emit('stepComplete');
};
</script>

<template>
  <UIForm
    :buttonVariant="{
      variant: 'primary',
      isFullwidth: false,
      position: 'center',
    }"
    :submitButtonText="$t('general.formFields.submit.label')"
    data-testid="auth-landing"
    @submit="requestCode"
  >
    <template #default="{ loading }">
      <UIFormInput
        v-model="loginState.email"
        :disabled="loading"
        :hideLabel="true"
        :label="$t('general.formFields.email.label')"
        :placeholder="`${$t('general.formFields.email.placeholder')} *`"
        :required="true"
        :rules="{ required, emailRule }"
        :focus="true"
        autocomplete="on"
        type="email"
      />
      <!-- add custom alert here -->
      <UITransitionFadeIn>
        <UIAlertMessageText v-if="result" :message="result.message" :variant="result.variant" class="mt-3" />
      </UITransitionFadeIn>
    </template>
  </UIForm>
</template>
