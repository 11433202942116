<script lang="ts" setup>
const emit = defineEmits<{ stepComplete: [] }>();

const getStarted = () => {
  emit('stepComplete');
};
</script>

<template>
  <div class="mt-5 flex justify-center">
    <UIButton
      :text="$t('general.auth.loginWithAnotherEmail')"
      variant="no-style"
      customClasses="text-secondary-500 underline"
      type="button"
      @click="getStarted"
    />
  </div>
</template>
