import type { LoginFlowType, SiteRoleType } from '~/graphql/generated/graphql';

export interface LoginState {
  email: string;
  otpCode: string;
  flow: null | LoginFlowType.ExistingRole | LoginFlowType.NewRole;
  hasPrefilled: boolean;
}

export interface LoginStepProps {
  roleType: SiteRoleType;
}

export const STEP_ENTER_EMAIL = 'enter-email';
export const STEP_ENTER_OTP = 'enter-otp';
export const STEP_STUDENT_REGISTRATION = 'student-registration';
export const STEP_TEACHER_REGISTRATION = 'teacher-registration';
export const STEP_TEACHER_REGISTRATION_DISABLED = 'teacher-registration-disabled';
export const STEP_TEACHER_REGISTRATION_COMPLETE = 'teacher-registration-complete';
export const STEP_INTEREST_ADDED = 'interest-added';

export type Step =
  | typeof STEP_ENTER_EMAIL
  | typeof STEP_ENTER_OTP
  | typeof STEP_STUDENT_REGISTRATION
  | typeof STEP_TEACHER_REGISTRATION
  | typeof STEP_TEACHER_REGISTRATION_DISABLED
  | typeof STEP_TEACHER_REGISTRATION_COMPLETE
  | typeof STEP_INTEREST_ADDED;
// | typeof STEP_RESTORE_USER
